<template>
    <b-container fluid>
        <div class="card card-primary" style="max-height: 85vh">
            <div class="card-header">
                <h3 class="card-title">Neue Lieferantenbestellung anlegen</h3>
            </div>

            <div class="card-body">
                <div class="row" v-if="step == 1">
                    <div class="col-md-6">
                        <div class="card card-primary card-outline" style="height: 65vh">
                            <div class="card-header">
                                <h2 class="card-title">Verfügbare Artikel</h2>
                                <div class="card-tools">
                                    <div class="input-group input-group-sm">
                                        <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getAllArticles" v-model="search" />
                                        <button type="submit" class="btn btn-default btn-sm" @click.prevent="getAllArticles">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body overflow-auto">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>Artikelname</th>
                                            <th>Hersteller</th>
                                            <th>Preis</th>
                                            <th>Flags</th>
                                        </tr>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>
                                            <th>
                                                <select v-model="params.producer_id" class="form-control form-control-sm">
                                                    <option :value="''">-- Filter Hersteller --</option>
                                                    <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.number }} - {{ producer.name  }}</option>
                                                </select>
                                            </th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article,index) in articles.data" :key="article.id" :class="article.article.active == 0 ? 'table-danger' : ''" @dblclick="addArticle(index)">
                                            <td>{{ article.ordernumber }}</td>
                                            <td>{{ generateName(index) }}</td>
                                            <td>{{ article.article.producer.name }}</td>
                                            <td>{{ article.price | toCurrency}}</td>
                                            <td>
                                                <span class="ml-2 badge bg-primary" v-if="article.laststock == 1">AdS</span>
                                                <span class="ml-2 badge bg-danger" v-if="article.not_available == 1">z.Z.n.l.</span>
                                                <span class="ml-2 badge bg-success" v-if="article.mhd != null">MHD</span>
                                                <span class="ml-2 badge bg-warning" v-if="article.article.seasonal == 1">Saison </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>

                            <div class="clearfix align-middle card-footer">
                                <div class="row">
                                    <div class="col-md-12">
                                        *rot hinterlegte Artikel sind deaktivierte Artikel. Sie können aber bei dem Lieferanten bestellt werden!
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-12">
                                        <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
                                
                                        <select class="float-right form-control-sm" v-model="params.per_page">
                                            <option value="25">25 Artikel</option>
                                            <option value="50">50 Artikel</option>
                                            <option value="75">75 Artikel</option>
                                            <option value="100">100 Artikel</option>
                                            <option value="125">125 Artikel</option>
                                            <option value="150">150 Artikel</option>
                                        </select>
                                        <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card card-success card-outline" style="height: 65vh">
                            <div class="card-header">
                                <h2 class="card-title">Bestellte Artikel</h2>
                            </div>
                            <div class="card-body overflow-auto">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Artikelnummer</th>
                                            <th>Artikelname</th>
                                            <th>Hersteller</th>
                                            <th>Preis</th>
                                            <th>Anzahl</th>
                                            <th>Aktion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article,index) in selectedArticles" :key="article.id">
                                            <td>{{ article.ordernumber }}</td>
                                            <td>{{ article.name }}</td>
                                            <td>{{ article.producer }}</td>
                                            <td>{{ article.price | toCurrency}}</td>
                                            <td><input type="number" class="form-control form-control-sm" v-model="selectedArticles[index].quantity" /></td>
                                            <td><button type="button" class="btn btn-danger btn-sm" @click="deleteArticle(index)"><i class="fas fa-trash"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="step == 2">
                    <div class="col-md-12">
                        <div class="accordion" id="orderAccordion">
                            <div class="card" v-for="(data, index) in orderData" :key="data.id">
                                <div class="card-header" :id="'heading'+data.id">
                                    <div class="row align-items-center">
                                        <div class="col-md-6">
                                            <h2 class="mb-0">
                                                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" :data-target="'#collapse'+data.id" aria-expanded="true" :aria-controls="'collapse'+data.id">
                                                {{ data.name }} <br> {{ data.street }} {{ data.house_number }} <br> {{ data.zipcode }} {{ data.city }}
                                                </button>
                                            </h2>
                                        </div>

                                        <div class="col-md-2">
                                            <span><strong>Verfügbare Bestelloptionen:</strong> <br>
                                            <i v-if="data.send_email == 1" class="fas fa-lg fa-envelope"></i> <i v-if="data.send_phone == 1" class="fas fa-lg fa-phone"></i> <i v-if="data.send_web == 1" class="fab fa-lg fa-internet-explorer"></i><span v-if="data.send_email == 0 && data.send_phone == 0 && data.send_web == 0">Keine Infos in den Stammdaten. Bestellung wird nur gespeichert</span></span>
                                        </div>


                                        <div class="col-md-2">
                                            <div class="form-group" v-if="data.send_email == 1">
                                                <!-- <span>Bei disem Lieferantan kann direkt per Mail bestellt werden</span> -->
                                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                    <input type="checkbox" class="custom-control-input" :id="'customSwitch'+data.id" v-model="orderData[index].autoSend">
                                                    <label class="custom-control-label" :for="'customSwitch'+data.id">Bestellung direkt versenden?</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <div class="form-group row">
                                                <label class="col-sm-6 col-form-label">Lieferdatum: </label>
                                                <div class="col-sm-6">
                                                    <input type="date" class="form-control form-control-sm" v-model="orderData[index].delivery_date" />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>

                                <div :id="'collapse'+data.id" class="collapse" :aria-labelledby="'heading'+data.id" data-parent="#orderAccordion">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <h3>Informationen zur Bestellung</h3>
                                                <textarea class="form-control form-control-sm" placeholder="Teile dem Lieferanten etwas mit" rows="5" v-model="orderData[index].infos"></textarea>
                                            </div>
            
                                            <div class="col-md-8">
                                                <h3>Bestellte Artikel</h3>
                                                <table class="table table-striped table-bordered">
                                                    <thead>
                                                        <th>Artikelnummer</th>
                                                        <th>HAN</th>
                                                        <th>Artikelname</th>
                                                        <th>Anzahl</th>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(item,index2) in data.items" :key="item.id">
                                                            <td>{{ item.ordernumber }}</td>
                                                            <td>{{ item.article.han }}</td>
                                                            <td>{{ item.article.name }}</td>
                                                            <td><input type="number" class="form-control form-control-sm" v-model="orderData[index].items[index2].quantity" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer text-right">
                <button type="button" class="btn btn-sm btn-secondary mr-1" v-if="step > 1" @click="goBack"><i class="fas fa-arrow-left"></i> Zurück</button>
                <button v-if="step == 1" type="button" class="btn btn-sm btn-primary" :disabled="selectedArticles.length <= 0" @click="goToStep2"><i class="fas fa-arrow-right"></i> Weiter</button>
                <button v-if="step == 2" type="button" class="btn btn-sm btn-primary" :disabled="selectedArticles.length <= 0" @click="doOrder"><i class="fas fa-arrow-right"></i> Bestellungen ausführen</button>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {

    data(){
        return {
            taxes: [],
            producers: {},
            articles: {},
            selectedArticles:[],
            step: 1,
            orderData: [],
            finalOrders: [],
            params: {
                sort_field: 'ordernumber',
                sort_direction: 'desc',
                per_page: 25,
                producer_id: '',
            },
            search: '',
        }
    },

     watch: {
        params: {
            handler () {
                this.getAllArticles(); 
            },
            deep: true
        },
    },

    methods: {
        doOrder(){
            this.finalOrders = [];
            this.orderData.forEach(function(order){
                //Erstellen für jeden Lieferanten die benötigten Daten
                var items = [];

                order.items.forEach(function(item){
                    items.push({
                        'name': item.article.name,
                        'qty': item.quantity,
                        'han': item.han,
                        'ordernumber': item.ordernumber,
                        'unit_name': item.unit_name
                    })
                });

                this.finalOrders.push({
                    'distributor_id': order.id,
                    'wishdate': order.wishdate,
                    'infos': order.infos,
                    'autoSend': order.autoSend == true ? 1 : 0,
                    'items': items,
                });
            },this);

            this.axios
            .post("/supplier-orders", {
                'orders': this.finalOrders,
            })
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Bestellung erstellt",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                this.$router.push({name: 'supplier-orders.index'});
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },

        goBack(){
            this.step -= 1;
        },

        goToStep2(){
            // var ids = [];
            // this.selectedArticles.forEach(function(item){
            //     ids.push(item.article_detail_id)
            // });

            this.axios
                .post("/supplier-orders/generate", {
                    'articles': this.selectedArticles,
                })
                .then((response) => {
                    this.orderData = response.data;
                    this.step = 2;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
            
        },

        deleteArticle(index){
            this.$delete(this.selectedArticles, index);
        },

        addArticle(index){
            var article = this.selectedArticles.find(item => item.article_detail_id == this.articles.data[index].id);
            if(article == undefined)
            {
                console.log(this.articles.data[index].purchaseunit);
                this.selectedArticles.push({
                    ordernumber: this.articles.data[index].ordernumber,
                    name: this.generateName(index),
                    producer: this.articles.data[index].article.producer.name,
                    price: this.articles.data[index].price,
                    quantity: 1,
                    article_detail_id: this.articles.data[index].id,
                    // unit_name: this.articles.data[index].purchaseunit + ' ' + this.articles.data[index].unit.description
                    unit_name: this.articles.data[index].purchaseunit + ' ' + this.articles.data[index].unit.description,
                    han:  this.articles.data[index].han
                });    
            }
            else
            {
                article.quantity += 1;
            }
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        generateName(index)
        {
            var article = this.articles.data[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
            
        },

        getAllArticles(page = 1) {
            this.$Progress.start();
            this.axios
                .get("/details", {
                    params: {
                        page,
                        search: this.search.length >= 4 ? this.search : '',
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers", {
                    params: {
                        per_page: 1000
                    }
                })
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadTaxes(){
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },
    
    mounted() {
        this.loadTaxes();
        this.getAllArticles();
        this.getProducers();
  }

}
</script>

<style>

</style>